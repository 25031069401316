import { useState } from "react";
import Appstore from "../assets/German-appstore.png";
import Playstore from "../assets/German-playstore.png";
import IPhone from "../assets/screenShotBanner.png";
import BenefitsCard from "./benefitsCard";
import GetAppPopUp from "./getAppPopUp";


const Banner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  const handleAppDownload = () => {
    const userAgent= navigator.userAgent
    // Check if the user is on an Android device
    if (/android/i.test(userAgent)) {
      window.location.href = "https://pensionflow.de/";
    }
    // Check if the user is on an iOS device
    else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      window.location.href = "https://apps.apple.com/us/app/pensionflow/id6737458527";
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <div className="bg-gradient-to-r from-[#F7F6FF] to-[#D4CFFF] grid grid-row-2 min-h-screen">
        <div className="container grid grid-cols-1 lg:grid-cols-2">
          <div className="py-8 px-4">
            <h1 className="font-bold poppins-bold text-[24px] leading-[31.2px] sm:text-[38px] sm:leading-[49.4px]">
              Deine Altersvorsorge: einfach, verständlich und sicher.
            </h1>
            <div className="mt-[32px] space-y-[20px]">
              <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
                <strong className="font-medium text-[#7C4EFB]">Investiere monatlich mit Pensionflow in ETFs. </strong>
                Profitiere vom Zinseszins, von Steuervorteilen und vielem mehr – speziell auf die Altersvorsorge zugeschnitten. Besser als ein herkömmlicher ETF-Sparplan.
              </p>
              <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
                <strong className="font-medium text-[#7C4EFB]">Jederzeit Kapitalauszahlung oder lebenslange Rente. </strong>{" "}
                Entscheide selbst, ob du dein Kapital ausgezahlt haben möchtest oder bei Renteneintritt eine lebenslange Rente bevorzugst.
              </p>{" "}
              <p className="sm:leading-7 font-normal sm:text-[18px] text-[16px] leading-6">
                <strong className="font-medium text-[#7C4EFB]">Digital.</strong> Starte noch heute und beginne mit der Vorsorge über die Pensionflow App.
              </p>
            </div>
            <div className="md:flex md:flex-row gap-5 hidden mt-10">
              <img onClick={handleAppDownload} src={Playstore} className="h-12" alt="Pensionflow Playstore" loading="lazy"/>
              <img onClick={handleAppDownload} src={Appstore} className="h-12" alt="Pensionflow App Store" loading="lazy"/>
              <div className="trustpilot-widget" data-locale="de-DE" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="672627c3261bfeb02d6268e5" style={{ height: 45 }}>
                <a href="https://de.trustpilot.com/review/pensionflow.de" target="_blank" rel="noopener"></a>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="flex flex-col lg:block justify-center items-center">
              <img className="w-36 md:w-42 lg:w-[280px]" src={IPhone} alt="Altersvorsorge einfach per App" loading="lazy"/>
              <div className="trustpilot-widget md:hidden" data-locale="de-DE" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="672627c3261bfeb02d6268e5" style={{ height: 80, paddingTop: 20}}>
                <a href="https://de.trustpilot.com/review/pensionflow.de" target="_blank" rel="noopener"></a>
              </div>
            </div>
          </div>
        </div>
        <BenefitsCard />
        <GetAppPopUp isOpen={isModalOpen} closeModal={closeModal}/>
    </div>
  );
};

export default Banner;
