import { ChevronRight } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/pensionflow_logo_transparent 2.png";
import GetAppPopUp from "./getAppPopUp";
import QrCodeComponent from "./QrCodeComponent";
import { Button } from "./ui/button";
import FAQIcon from "../assets/FAQ.png";
import VideoPopUp from "./VideoPopUp";
import YoutubeIcon from '../assets/youtube.png';
import CalculatorIcon from '../assets/calculator.png';

interface NavItemProps {
  isOnHome: boolean;
}

const Navbar = ({isOnHome}: NavItemProps) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const nav = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const closeVideModal = () => setIsVideoModalOpen(false);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleAppDownload = () => {
    const userAgent= navigator.userAgent
    console.log(userAgent)
    // Check if the user is on an Android device
    if (/android/i.test(userAgent)) {
      window.location.href = "https://pensionflow.de/";
    }
    // Check if the user is on an iOS device
    else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      window.location.href = "https://apps.apple.com/us/app/pensionflow/id6737458527";
    } 
    // If neither Android nor iOS, handle appropriately
    else {
      alert("App is available on Google Play Store and Apple App Store.");
    }
  };

  return (
    <>
      <div className={`sticky flex h-20 top-0 z-50 ${
          isScrolled ? "bg-[#fffc] backdrop-filter backdrop-blur-lg" : "bg-gradient-to-r from-[#F7F6FF] to-[#D4CFFF]"
        }`}>
          <div className="container h-20">
            <nav className="px-4 h-20">
              {/*Mobile Version*/}
              <div className="flex lg:hidden items-center justify-between h-20">
                <img
                  src={Logo}
                  className="h-8"
                  alt="Pensionflow"
                  loading="lazy"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    nav('/');
                  }}
                />

                <div className="flex flex-row items-center gap-5">
                  <a onClick={() => setIsVideoModalOpen(true)} className="cursor-pointer">
                    <img src={YoutubeIcon} width={25}/>
                  </a>
                  {isOnHome &&
                    <a href="/#pensionCalculator" className="cursor-pointer">
                      <img src={CalculatorIcon} width={25}/>
                    </a>
                  }
                  {isOnHome &&
                    <a href="/#faq" className="cursor-pointer">
                      <img src={FAQIcon} width={25}/>
                    </a>
                  }
                </div>
              </div>

              {/*Tablets Version*/}
              {/*<div className="hidden md:flex lg:hidden justify-between h-20">
                <div className="flex items-center">
                  <img
                    src={Logo}
                    className="h-8"
                    alt="Pensionflow"
                    loading="lazy"
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      nav('/');
                    }}
                  />
                </div>

                <div className="flex flex-row gap-7 h-20">
                  <div className="flex flex-row items-center gap-5">
                    <div className="flex flex-row gap-2 items-center">
                      <img src={YoutubeIcon} style={{ width: 20, height: 20 }}/>
                      <a onClick={() => setIsVideoModalOpen(true)} className="cursor-pointer">
                        In 100 Sekunden erklärt
                      </a>
                    </div>

                    <a href="/#pensionCalculator" className="cursor-pointer">
                      Rentenrechner
                    </a>
                    
                    {isOnHome &&
                      <a href="/#faq" className="cursor-pointer">
                        Häufige Fragen
                      </a>
                    }
                  </div>
                  <QrCodeComponent
                    setIsModalOpen={setIsModalOpen}
                  />
                </div>
              </div>*/}

              {/*Desktop Version*/}
              <div className="hidden lg:flex justify-between h-20">
                <div className="flex items-center">
                  <img
                    src={Logo}
                    className="h-8"
                    alt="Pensionflow"
                    loading="lazy"
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      nav('/');
                    }}
                  />
                </div>

                <div className="flex flex-row items-start gap-7">
                  <div className="flex flex-row h-20 items-center gap-7">
                    <div className="flex flex-row gap-2 items-center">
                      <img src={YoutubeIcon} style={{ width: 20, height: 20 }}/>
                      <a onClick={() => setIsVideoModalOpen(true)} className="cursor-pointer">In 100 Sekunden erklärt</a>
                    </div>
                    <a href="/#pensionCalculator"
                      className="cursor-pointer">
                      Rentenrechner
                    </a>
                    
                    <a href="/#faq" className="cursor-pointer">Häufige Fragen</a>
                    
                  </div>
                  <QrCodeComponent
                    setIsModalOpen={setIsModalOpen}
                  />
                </div>
              </div>
            </nav>
          </div>
      </div>


      <div className="fixed bottom-0 right-3 mb-3 sm:hidden z-50">
        <Button onClick={handleAppDownload} className="text-white bg-[#02000B] px-3">
          Hol dir die App <ChevronRight className="h-5 w-5" />
        </Button>
      </div>
      <GetAppPopUp isOpen={isModalOpen} closeModal={closeModal}/>
      <VideoPopUp
        isOpen={isVideoModalOpen}
        closeModal={closeVideModal}
      />
    </>
  );
};

export default Navbar;
